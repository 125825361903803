export const MOCK_DATA = [
  {
    id: 1,
    title: 'Jesuitas del Clot',
    subTitle: 'Desarrollo de Aplicaciones Web',
    from: new Date('2012-09-01'),
    to: new Date('2013-06-01'),
    location: 'Barcelona, Spain',
    description:
      'At Jesuitas del Clot I learned more focused at Web skills with PHP, .NET, HTML5, CSS3, Javascript, jQuery, AJAX, and more. I also played with Photoshop and Sony Vegas tools.'
  },
  {
    id: 2,
    title: 'Salesians de Sarrià',
    subTitle: 'Desarrollo de Aplicaciones Informáticas',
    from: new Date('2010-09-01'),
    to: new Date('2012-06-01'),
    location: 'Barcelona, Spain',
    description:
      'At Salesians de Sarrià was where I learn the basics of program languages like .NET, PHP, SQL, HTML5, jQuery, and more. During the two years I build different applications like: Emulate a Online Bank with transfers, stats, and more, or an Invoice and Tickets tool.'
  }
]
