import {HistoryList} from '@/components/common/HistoryList/index.js'
import Section from '@/components/common/Section/index.js'

import {MOCK_DATA} from './settings.js'
import {UIEducation} from './styles/ui.js'

export default function Education() {
  return (
    <Section
      title="My Education"
      description="After ends Bachelor, I decided to start my careear as a developer. And I did two VETs (Vocational Education and Training) to learn the basics, "
    >
      <UIEducation>
        <HistoryList list={MOCK_DATA} />
      </UIEducation>
    </Section>
  )
}
